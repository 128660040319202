/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FontAwesome from 'react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Table, Card, CardBody, CardTitle } from 'reactstrap';
import ReactTable from '../Styled/Table';
import matchSorter from 'match-sorter';
import { addressType, onCloseType, transactionType } from '../types';
import Modal from '../Styled/Modal';
import Pagination from '@mui/material/Pagination';
import { get } from '../../services/request';
import Auth from "../../state/Auth";
import Dialog from '@material-ui/core/Dialog';
import TransactionView from '../View/TransactionView';

const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		cubeIcon: {
			color: '#ffffff',
			marginRight: 20
		},
		table: {
			'& .rt-thead .rt-tr': {
				padding: '10px 0'
			},
			'& .rt-thead .rt-th': {
				color: 'rgb(102, 102, 102)',
				'font-family': 'PingFang SC',
				'font-size': '14px',
				'font-weight': '500',
			},
		},
		partialHash: {
			cursor:"copy",
			textAlign: 'center',
			position: 'relative !important',
			'&:hover $lastFullHash': {
				marginLeft: -400
			},
			'&:hover $fullHash': {
				display: 'block',
				position: 'fixed !important',
				padding: '4px 4px',
				backgroundColor: dark ? '#5e558e' : '#000000',
				marginTop: -30,
				marginLeft: -215,
				borderRadius: 8,
				color: '#ffffff',
				opacity: dark ? 1 : undefined
			}
		},
		fullHash: {
			display: 'none'
		},
	}
};
const tablePaginationStyle = {
	display: 'flex',
	justifyContent: 'center',
	padding: '0px 15px',
	alignItems: 'baseline',
	'.MuiToolbar-root': {
		alignItems: 'baseline'
	}
};
export class AddressView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			pageNo: 1,
			loading: false,
			dataList: [],
			pages: 0,
			dialogOpen: false,
		};
	}
	handleClose = () => {
		const { onClose } = this.props;
		onClose();
	};

	componentDidMount() {
		this.fetchData(this.state.pageNo);
	}

	// componentDidUpdate(prevProps) {
	// 	if (prevProps.address !== this.props.address) {
	// 		this.fetchData();
	// 	}
	// }

	fetchData(pageNo) {
		const { address } = this.props;
		this.setState({ loading: true });
		if (address) {
			get(`/api/fetchDataByAddress?pageNo=${pageNo}&pageSize=5&address=${address}`)
				.then(res => {
					if(res.data.code != 200){
						this.props.setError(res.data.msg || 'No data found');
						this.props.onClose();
						return;
					}
					this.setState({ dataList: res.data.data.list, loading: false, pages: res.data.data.pages });
				})
				.catch(error => {

				});
		}
	}

	handlePageChange = (_e, page) => {
		this.setState({ pageNo: page });
		this.fetchData(page);
	};

	formatDate(isoString) {
		const date = new Date(isoString);
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	}

	handleDialogOpen = async tid => {
		const { getTransaction } = this.props;
		getTransaction(tid)
		// const { currentChannel, getTransaction } = this.props;
		// alert(currentChannel)
		// await getTransaction(currentChannel, tid);
		this.setState({ dialogOpen: true });
		// if (this.props.transactionId) {
		// 	this.setState({ directLinkDialogDoneFlag: true });
		// }
	};

	handleDialogClose = () => {
		this.setState({ dialogOpen: false });
	};

	render() {
		const { transaction, classes } = this.props;
		const columnHeaders = [
			{
				Header: 'TX ID',
				accessor: 'txId',
				Cell: row => (
					<span style={{ color: '#0080ff', cursor: 'pointer' }} onClick={() => this.handleDialogOpen(row.value)}>
						{row.value}
					</span>
				),
				filterMethod: (filter, rows) =>
					matchSorter(
						rows,
						filter.value,
						{ keys: ['txId'] },
						{ threshold: matchSorter.rankings.SIMPLEMATCH }
					),
				filterAll: true
			},
			{
				Header: '区块哈希',
				accessor: 'blockHash',
				Cell: row => (
					<div className={classes.partialHash}>
						<div className={classes.fullHash}>
							{row.value}
						</div>{' '}
						{row.value && row.value.length > 8 && row.value.slice(0, 4) +'...' + row.value.slice(-4)}
					</div>
				),
				filterMethod: (filter, rows) =>
					matchSorter(
						rows,
						filter.value,
						{ keys: ['blockHash'] },
						{ threshold: matchSorter.rankings.SIMPLEMATCH }
					),
				filterAll: true
			},
			{
				Header: '区块高度',
				accessor: 'heightNumber',
				filterMethod: (filter, rows) =>
					matchSorter(
						rows,
						filter.value,
						{ keys: ['heightNumber'] },
						{ threshold: matchSorter.rankings.SIMPLEMATCH }
					),
				filterAll: true
			},
			{
				Header: '数量',
				accessor: 'amountAvailable',
				filterMethod: (filter, rows) =>
					matchSorter(
						rows,
						filter.value,
						{ keys: ['amountAvailable'] },
						{ threshold: matchSorter.rankings.SIMPLEMATCH }
					),
				filterAll: true
			},
			{
				Header: '手续费',
				accessor: 'amountFee',
				filterMethod: (filter, rows) =>
					matchSorter(
						rows,
						filter.value,
						{ keys: ['amountFee'] },
						{ threshold: matchSorter.rankings.SIMPLEMATCH }
					),
				filterAll: true
			},
			{
				Header: 'From',
				accessor: 'fromAddress',
				Cell: row => (
					<div className={classes.partialHash}>
						<div className={classes.fullHash}>
							{row.value}
						</div>{' '}
						{row.value && row.value.length > 8 && row.value.slice(0, 4) +'...' + row.value.slice(-4)}
					</div>
				),
				filterMethod: (filter, rows) =>
					matchSorter(
						rows,
						filter.value,
						{ keys: ['fromAddress'] },
						{ threshold: matchSorter.rankings.SIMPLEMATCH }
					),
				filterAll: true
			},
			{
				Header: 'To',
				accessor: 'toAddress',
				Cell: row => (
					<div className={classes.partialHash}>
						<div className={classes.fullHash}>
							{row.value}
						</div>{' '}
						{row.value && row.value.length > 8 && row.value.slice(0, 4) +'...' + row.value.slice(-4)}
					</div>
				),
				filterMethod: (filter, rows) =>
					matchSorter(
						rows,
						filter.value,
						{ keys: ['toAddress'] },
						{ threshold: matchSorter.rankings.SIMPLEMATCH }
					),
				filterAll: true
			},
			{
				Header: '时间',
				accessor: 'ctime',
				Cell: row => (
					<span>
						{this.formatDate(row.value)}
					</span>
				),
				filterMethod: (filter, rows) =>
					matchSorter(
						rows,
						filter.value,
						{ keys: ['ctime'] },
						{ threshold: matchSorter.rankings.SIMPLEMATCH }
					),
				filterAll: true
			},
		];
		if (this.state.loading) {
			return (
				<Modal>
					{modalClasses => (
						<Card className={modalClasses.card}>
							<CardTitle className={modalClasses.title}>
								<FontAwesome name="cube" />
								Address Details
							</CardTitle>
							<CardBody style={{ 'text-align': 'center' }} className={modalClasses.body}>
								<span>
									{' '}
									<FontAwesome name="circle-o-notch" size="3x" spin />
								</span>
							</CardBody>
						</Card>
					)}
				</Modal>
			);
		}
		return (
			<>
				<Modal>
					{modalClasses => (
						<div className={modalClasses.dialog}>
							<Card className={modalClasses.card}>
								<CardTitle className={modalClasses.title}>
									<FontAwesome name="cube" className={classes.cubeIcon} />
									Address Details
									<button
										type="button"
										onClick={this.handleClose}
										className={modalClasses.closeBtn}
									>
										<FontAwesome name="close" />
									</button>
								</CardTitle>
								<CardBody className={modalClasses.body}>
									<ReactTable
										className={classes.table}
										data={this.state.dataList}
										columns={columnHeaders}
										defaultPageSize={5}
										filterable
										minRows={0}
										showPagination={false}
									/>
									<Pagination
										count={this.state.pages}
										defaultPage={1}
										page={this.state.pageNo}
										sx={tablePaginationStyle}
										color="primary"
										onChange={this.handlePageChange}
									/>
								</CardBody>
							</Card>
						</div>
					)}
				</Modal>
				<Dialog
					open={this.state.dialogOpen}
					onClose={this.handleDialogClose}
					fullWidth
					maxWidth="md"
				>
					<TransactionView
						transaction={transaction}
						onClose={this.handleDialogClose}
					/>
				</Dialog>
			</>
		);
	}
}

AddressView.propTypes = {
	address: addressType.isRequired,
	onClose: onCloseType.isRequired,
	transaction: transactionType
};

export default withStyles(styles)(AddressView);
